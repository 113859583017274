import React, { useState, useEffect, useRef } from 'react';

const BarcodeReader = () => {
  const [hasPermission, setHasPermission] = useState(false);
  const [scannedCode, setScannedCode] = useState('');
  const videoRef = useRef(null);
  const barcodeDetectorRef = useRef();

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' },audio:false })
      .then(stream => {
        videoRef.current.srcObject = stream;
        setHasPermission(true);
      })
      .catch(error => {
        console.error('Error accessing camera:', error);
      });
  }, []);

  const detectCode=()=>{
    const barcodeDetect = new window.BarcodeDetector({
        formats: ['qr_code', 'ean_13', 'ean_8', 'code_128'],
      });
    barcodeDetect.detect(videoRef.current)
    .then(codes => {
      console.log("codes",codes)
      if (codes.length > 0) {
        setScannedCode(codes[0].rawValue);
      }
    })
    .catch(error => {
      console.error('Error detecting barcode:', error);
    });
  }
  useEffect(() => {
    if (hasPermission && videoRef.current) {
     

      setInterval(detectCode, 100);

    }
  }, [hasPermission]);

  return (
    <div>
      <video ref={videoRef} width={640} height={480}  autoPlay={true}/>
      {scannedCode && (
        <p>Scanned code: {scannedCode}</p>
      )}
    </div>
  );
};

export default BarcodeReader;