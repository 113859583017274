import logo from './logo.svg';
import './App.css';
import QrScanner from './components/QrScanner';

function App() {
  return (
    <div className="App">
     <QrScanner/>
    </div>
  );
}

export default App;
